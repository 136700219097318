
		import React from 'react'
		export default {
  darkMode: props => <svg viewBox="0 0 48 48" {...props}><path fillRule="evenodd" clipRule="evenodd" d="M20.1011 0.31543C8.70056 2.1779 0 12.0731 0 24.0003C0 37.2552 10.7452 48.0003 24 48.0003C28.0011 48.0003 31.7734 47.0213 35.0906 45.2896C23.8473 40.3269 16 29.08 16 16.0003C16 10.3013 17.4898 4.95031 20.1011 0.31543Z" /></svg> ,
  dribbble: props => <svg viewBox="0 0 48 48" {...props}><path d="M24 0C10.8 0 0 10.8 0 24C0 37.2 10.8 48 24 48C37.2 48 48 37.2 48 24C48 10.8 37.2 0 24 0ZM39.2 11.2C42 14.4 43.6 18.6 43.8 22.8C38.6 22 34 22 29.6 22.6C29.2 21.4 28.6 20.4 28 19.2C32.6 17.4 36.4 14.6 39.2 11.2V11.2ZM24 4.2C28.6 4.2 32.8 5.8 36 8.4C33.6 11.6 30.2 14 26 15.8C24 12 21.6 8.4 19 5C20.8 4.4 22.4 4.2 24 4.2V4.2ZM4.8 19.4C6.2 13.8 10 9 15 6.4C17.6 9.8 20 13.4 22 17.2C17.2 18.6 11.4 19.2 4.8 19.4V19.4ZM4.2 24V23.4C11.8 23.4 18.6 22.6 24.2 20.8C24.6 21.8 25.2 22.8 25.6 23.6C18.8 25.8 13.2 30 9 36.6C5.8 33.2 4.2 28.6 4.2 24V24ZM11.8 39.6C15.8 33.4 20.8 29.4 27 27.6C28.8 32.4 30.2 37.4 31 42.6C24.4 45 17.2 43.8 11.8 39.6V39.6ZM35 40.4C34.2 35.6 32.8 31 31.2 26.6C35 26 39 26.2 43.6 27C42.8 32.6 39.6 37.4 35 40.4V40.4Z" /></svg> ,
  flags_de_DE: props => <svg fill="none" height={12} viewBox="0 0 16 12" width={16} {...props}><path d="m16 0h-16v12h16z" fill="#c0cfd8" /><path d="m15.5.509399h-15v3.500001h15z" fill="#272833" /><path d="m15.5 8h-15v3.5h15z" fill="#ffb700" /><path d="m15.5 4h-15v4h15z" fill="#ff4040" /></svg> ,
  flags_en_US: props => <svg fill="none" height={12} viewBox="0 0 16 12" width={16} {...props}><path d="m16 0h-16v12h16z" fill="#c0cfd8" /><path d="m15.5.5h-15v1h15z" fill="#e03232" /><path d="m15.5 1.5h-15v1h15z" fill="#fff" /><path d="m15.5 2.5h-15v1h15z" fill="#e03232" /><path d="m15.5 3.5h-15v1h15z" fill="#fff" /><path d="m15.5 4.5h-15v1h15z" fill="#e03232" /><path d="m15.5 5.5h-15v1h15z" fill="#fff" /><path d="m15.5 6.5h-15v1h15z" fill="#e03232" /><path d="m15.5 7.5h-15v1h15z" fill="#fff" /><path d="m15.5 8.5h-15v1h15z" fill="#e03232" /><path d="m15.5 9.5h-15v1h15z" fill="#fff" /><path d="m15.5 10.5h-15v1h15z" fill="#e03232" /><path d="m8.5.5h-8v6h8z" fill="#66a1cc" /><path d="m8.5.5h-8v6h8z" fill="#0035a0" /></svg> ,
  flags_es_ES: props => <svg fill="none" height={12} viewBox="0 0 16 12" width={16} {...props}><path d="m16 0h-16v12h16z" fill="#c0cfd8" /><path d="m15.5.509399h-15v3.500001h15z" fill="#e03232" /><path d="m15.5 8h-15v3.5h15z" fill="#e03232" /><path d="m15.5 4h-15v4h15z" fill="#fcd638" /></svg> ,
  flags_it_IT: props => <svg fill="none" height={12} viewBox="0 0 16 12" width={16} {...props}><path d="m16 0h-16v12h16z" fill="#c0cfd8" /><path d="m5.5.5h-5v11h5z" fill="#75bd4a" /><path d="m15.5.5h-5v11h5z" fill="#e03232" /><path d="m10.5.5h-5v11h5z" fill="#fff" /></svg> ,
  flags_pt_BR: props => <svg fill="none" height={12} viewBox="0 0 16 12" width={16} {...props}><path d="m16 0h-16v12h16z" fill="#c0cfd8" /><path d="m15.5.5h-15v11h15z" fill="#75bd4a" /><path d="m8 11-7-5 7-5 7 5z" fill="#fcd638" /><path d="m8 8.5c1.38071 0 2.5-1.11929 2.5-2.5s-1.11929-2.5-2.5-2.5-2.5 1.11929-2.5 2.5 1.11929 2.5 2.5 2.5z" fill="#0035a0" /></svg> ,
  github: props => <svg viewBox="0 0 48 48" {...props}><path d="M24.12 0C10.852 0 0 10.8359 0 24.0779C0 34.6728 6.99401 43.8215 16.4 46.9527C17.606 47.1937 18.09 46.4707 18.09 45.7476V41.6563C11.336 43.1024 9.89002 38.5271 9.89002 38.5271C8.92402 35.6368 7.23601 34.9138 7.23601 34.9138C5.06601 33.4697 7.47601 33.4697 7.47601 33.4697C9.88802 33.7107 11.096 35.8779 11.096 35.8779C13.266 39.7302 16.884 38.5271 18.09 38.045C18.33 36.5989 18.812 35.3958 19.536 34.9158C14.472 34.1927 8.68202 32.0255 8.68202 22.8768C8.68202 20.2256 9.64802 18.0604 11.094 16.3753C10.854 15.6522 10.13 13.2441 11.334 9.87379C11.334 9.87379 13.266 9.15073 17.848 12.282C20.018 12.041 22.188 11.5589 24.118 11.5589C26.048 11.5589 28.2181 11.7999 30.1481 12.282C34.7301 9.15073 36.6601 9.87379 36.6601 9.87379C37.8661 13.2441 37.1421 15.6522 36.9001 16.1343C38.4714 17.9308 39.3306 20.2447 39.3141 22.6358C39.3141 31.7845 33.7661 33.9517 28.2181 34.4337C29.1841 35.1568 29.9061 36.6009 29.9061 39.0071V45.5086C29.9061 46.2317 30.3901 46.9547 31.5961 46.7137C41.2421 43.5825 47.9961 34.4337 47.9961 23.8389C48.2361 10.8359 37.3841 0 24.118 0H24.12Z" /></svg> ,
  lightMode: props => <svg viewBox="0 0 48 48" {...props}><path d="M22.5216 0.622664C23.3149 -0.248045 24.6851 -0.248045 25.4784 0.622664L28.3129 3.7337C28.8391 4.3113 29.6536 4.52953 30.3981 4.29244L34.4083 3.01543C35.5307 2.65803 36.7173 3.34313 36.969 4.49383L37.8682 8.6053C38.0351 9.36864 38.6314 9.96486 39.3947 10.1318L43.5062 11.031C44.6569 11.2827 45.342 12.4693 44.9846 13.5917L43.7076 17.6019C43.4705 18.3464 43.6887 19.1609 44.2663 19.6871L47.3773 22.5216C48.248 23.3149 48.248 24.6851 47.3773 25.4784L44.2663 28.3129C43.6887 28.8391 43.4705 29.6536 43.7076 30.3981L44.9846 34.4083C45.342 35.5307 44.6569 36.7173 43.5062 36.969L39.3947 37.8682C38.6314 38.0351 38.0351 38.6314 37.8682 39.3947L36.969 43.5062C36.7173 44.6569 35.5307 45.342 34.4083 44.9846L30.3981 43.7076C29.6536 43.4705 28.8391 43.6887 28.3129 44.2663L25.4784 47.3773C24.6851 48.248 23.3149 48.248 22.5216 47.3773L19.6871 44.2663C19.1609 43.6887 18.3464 43.4705 17.6019 43.7076L13.5917 44.9846C12.4693 45.342 11.2827 44.6569 11.031 43.5062L10.1318 39.3947C9.96486 38.6314 9.36864 38.0351 8.60529 37.8682L4.49383 36.969C3.34313 36.7173 2.65803 35.5307 3.01543 34.4083L4.29244 30.3981C4.52953 29.6536 4.3113 28.8391 3.7337 28.3129L0.622664 25.4784C-0.248045 24.6851 -0.248045 23.3149 0.622664 22.5216L3.7337 19.6871C4.3113 19.1609 4.52953 18.3464 4.29244 17.6019L3.01543 13.5917C2.65803 12.4693 3.34313 11.2827 4.49383 11.031L8.6053 10.1318C9.36864 9.96486 9.96486 9.36864 10.1318 8.60529L11.031 4.49383C11.2827 3.34313 12.4693 2.65803 13.5917 3.01543L17.6019 4.29244C18.3464 4.52953 19.1609 4.3113 19.6871 3.7337L22.5216 0.622664Z" /></svg> ,
  medium: props => <svg {...props}><path d="M0 0h48v48H0z" /><path d="M13.987 18.08a.976.976 0 0 0-.317-.823l-2.352-2.834V14h7.303l5.646 12.381L29.23 14h6.962v.423l-2.01 1.929a.588.588 0 0 0-.224.564v14.168a.588.588 0 0 0 .223.564l1.964 1.929V34h-9.88v-.423l2.036-1.976c.2-.2.2-.258.2-.564V19.585l-5.658 14.368h-.764l-6.586-14.368v9.63c-.055.404.08.812.364 1.105l2.647 3.21v.423H11v-.423l2.646-3.21c.283-.293.41-.704.341-1.105V18.08z" fill="#fff" /></svg> ,
  twitter: props => <svg viewBox="0 0 48 48" {...props}><path d="M48 8.81473C46.3289 9.538 44.4178 10.0202 42.2684 10.2595C44.1796 9.05405 45.8507 7.12886 46.5671 4.7215C44.656 5.92695 42.5067 6.64846 40.3591 7.12886C38.688 5.20368 36.0604 4 33.1947 4C27.7013 4 23.4027 8.33255 23.4027 13.8705C23.4027 14.592 23.4027 15.3153 23.6409 16.0368C15.5218 15.7957 8.35911 11.9454 3.58222 5.92518C2.62756 7.36996 2.14933 9.05582 2.14933 10.981C2.14933 14.3527 3.82044 17.4816 6.448 19.1675C5.01511 19.1675 3.58222 18.6853 2.14933 17.962V18.2031C2.14933 23.0178 5.49156 26.8717 10.0302 27.8325C9.31378 28.0736 8.35911 28.0736 7.40267 28.0736C6.68622 28.0736 6.20978 28.0736 5.49333 27.8325C6.68622 31.6847 10.2684 34.5742 14.5671 34.5742C11.2249 37.2227 6.92622 38.9068 2.38933 38.9068C1.67111 38.9068 0.716444 38.9068 0 38.6675C4.29867 41.557 9.552 43 15.0453 43C32.9547 43 42.9849 27.8325 42.9849 14.8331V13.6295C45.136 12.4258 46.5671 10.7399 48 8.81473V8.81473Z" /></svg> ,
  unsplash: props => <svg viewBox="0 0 48 48" {...props}><path d="M15.75 14.375V2H32.25V14.375H15.75ZM32.25 21.25H46V46H2V21.25H15.75V33.625H32.25V21.25Z" /></svg> ,
  youtube: props => <svg {...props}><path d="M0 0h48v48H0z" /><path d="M36.74 18.857s-.26-1.8-1.04-2.571c-1.04-1.029-2.08-1.029-2.6-1.029C29.46 15 24 15 24 15s-5.46 0-9.1.257c-.52 0-1.56 0-2.6 1.029-.78.771-1.04 2.571-1.04 2.571S11 20.914 11 22.971V24.9c0 2.186.26 4.243.26 4.243s.26 1.8 1.04 2.571c1.04 1.029 2.34 1.029 2.86 1.157C17.24 33 24 33 24 33s5.46 0 9.1-.257c.52 0 1.56-.129 2.6-1.029.78-.771 1.04-2.571 1.04-2.571s.26-2.057.26-4.114V22.97c0-2.057-.26-4.114-.26-4.114zm-15.47 8.486v-7.2l7.02 3.6-7.02 3.6z" fill="#fff" /></svg> 
}
	